/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import axios from 'axios';
import { Spinner, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import EditIcon from '../../components/svg/EditIcon'
import DeleteIcon from '../../components/svg/DeleteIcon'
import useGetCompany from '../../hooks/companyHooks/useGetCompany';

const ManageCompany = () => {

    const { company, loading, setcompany } = useGetCompany();

    const token = localStorage.getItem('token')

    const companyDelete = async (id) => {

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/company/delete/${id = id}`, {
                headers: {
                    Authorization: 'Bearer' + ' ' + token,
                },
            });

            if (response.data.success) {
                toast.success(response.data.msg)

                const newcompany = company.filter(item => item.id !== id ? item : '')
                setcompany(newcompany);

            } else {
                toast.error(response.data.msg)
            }

        } catch (error) {
            console.log(error);

        }

    }



    return (
        <div className='container'>
            <ToastContainer />
            <div className='card p-3 rounded-0 border-0'>
                <div className='py-4 d-flex justify-content-between'>
                    <h2 className="text-secondary">Manage company</h2>
                    <div>
                        <Link to="/company/add" className='btn btn-primary'> + Add company</Link>
                    </div>
                </div>
                {
                    loading ? (
                        <div className='col-lg-3 mx-auto text-center'>
                            <Spinner animation="border" variant="primary" />
                        </div>
                    ) : (
                        <Table striped bordered hover>
                            <thead>
                                <tr className='text-center'>
                                    <th>No</th>
                                    <th>company</th>
                                    <th>company Slug</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    company?.map((item, index) => {
                                        return (
                                            <tr className='text-center' key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item?.company_name}</td>
                                                <td>{item?.company_slug}</td>
                                                <td className='d-flex align-items-center gap-4 justify-content-center'>
                                                    <Link to={`/company/update/${item?.id}`}><EditIcon /></Link>
                                                    <a href='#' onClick={() => companyDelete(item?.id)} className='text-danger'><DeleteIcon /></a>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }

                            </tbody>
                        </Table>
                    )
                }

            </div>
        </div>
    );
};

export default ManageCompany;