/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import axios from 'axios';

type companyType = {
    company_name:string
    company_slug:string
}

const useGetSpecificCompnay = (id:number) => {

    const [company, setCompany] = useState<companyType | null>(null);
    const token = localStorage.getItem('token')
 
    const getSpecificCompnay = async () => {

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/company/getitem/${id = id}`, {
                headers: {
                    Authorization: 'Bearer' + ' ' + token,
                },
            });

            if(response.data.company){
                setCompany(response.data.company)
            }

        } catch (error) {
            console.log(error);

        }

    }

    useEffect(() => {
        getSpecificCompnay()
    }, [])


    return {company};
};

export default useGetSpecificCompnay;