import { useEffect, useState } from 'react';
import axios from 'axios';

type Category = {
    id: number;
    category_name: string;
    category_slug: string;
}[];

const useCagory = () => {
    const [category, setCategory] = useState<Category | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);
    const token = localStorage.getItem('token');
    

    const getCategory = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/category`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setCategory(response.data.categories);
        } catch (error) {
            setError(error as Error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getCategory();
    }, []);

    return { category, setCategory, loading, error };
};

export default useCagory;
