import axios from 'axios';
import React, { useContext, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import { TokenContext } from '../../../context/TokenContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {

    const navigate = useNavigate();
    const [token, setToken] = useContext(TokenContext);
    const [loading, setLoading] = useState<boolean>(false)

    const handleLogin = async (event:React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        const form = event.target as HTMLFormElement;
     
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/user_login`, {
                email: form.email.value,
                password: form.password.value,
            });
            console.log(response)

            if (response.data.token) {
                
                localStorage.setItem("token", response.data.token);
                setToken(response.data)
                if(response.data.role === 'user'){
                    navigate("/user/manage/medicine");
                   
                }else{
                    navigate("/");
                }
               
                
            } else {
                toast.error('Login faild please try again')
            }

        } catch (error) {
            console.log(error);

        }finally{
            setLoading(false);
        }

    }

    return (
        <div className='container'>
            <ToastContainer />
            <div className='row'>
                <div className='col-lg-6 mx-auto'>
                    <div className='card p-5 mt-5'>
                    <h4 className="text-center pb-4 text-secondary">Login In Your Account</h4>
                        <form onSubmit={handleLogin}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" name="email" placeholder="Enter email" />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" name="password" placeholder="Password" />
                            </Form.Group>

                            <div className='text-center py-4'>
                                <Button variant="primary" type="submit">
                                    {loading ? <div className='d-flex align-items-center'><span className='pe-2'>Login</span><Spinner animation="border" style={{ width: '12px', height: '12px' }}  variant="light" /> </div> : "Login"}
                                </Button>
                            </div>
                        </form>

                        <div className="card-body text-center">
                            <Link to='/password/reset' className='nav-link'>Forget Password Password Reset</Link>
                        </div>

                        <div className="card-body text-center">
                            <Link to='/registration' className='nav-link'>Do not have any Account Registration</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;