import axios from 'axios';
import React, { useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PasswordReset = () => {
    const [loading, setLoading] = useState<boolean>(false)

    const handlePasswordReset = async (event:React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);

        const form = event.target as HTMLFormElement;

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/reset_password`, {
                email: form.email.value,
            });

            if(response.data.success === true){
                toast.success(response.data.msg)
            }else{
                toast.error(response.data.msg)
            }

        } catch (error) {
            console.log(error);

        }finally{
            setLoading(false);
        }
    }


    return (
        <div className='container pt-5 mt-5'>
            <ToastContainer />
            <div className='row'>
                <div className='col-lg-4 mx-auto'>
                    <div className='card p-3'>
                      <h2 className='text-secondary py-4 text-center'>Password Reset</h2>
                        <form onSubmit={handlePasswordReset}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" name="email" placeholder="Enter email" />
                            </Form.Group>

                            <div className='text-center py-4'>
                                <Button variant="primary" type="submit">
                                {loading ? <div className='d-flex align-items-center'><span className='pe-2'>Email Sending</span><Spinner animation="border" style={{ width: '12px', height: '12px' }}  variant="light" /> </div> : "Password Reset"}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PasswordReset;