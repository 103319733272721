import axios from 'axios';
import React, { useContext, useState } from 'react';
import { Form, Spinner, Table, Pagination } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ToastContainer} from 'react-toastify';
import { TokenContext } from '../../context/TokenContext';
import Loading from '../../components/loading/Loading';
import { usePDF } from 'react-to-pdf';
import useGetOrder from '../../hooks/orderHooks/useGetOrder';

const ManageOrder = () => {
    const { orders, loading } = useGetOrder();
    const [user] = useContext(TokenContext);

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [data, setFinalFilterData] = useState(orders);
    const [itemsPerPage, setItemsPrice] = useState(10)

    const { toPDF, targetRef } = usePDF({ filename: 'page.pdf' });

    const [currentPage, setCurrentPage] = useState(1); // For tracking the current page

    // Filter and Search functions
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const filteredData = orders?.filter(item =>
            item.medicine?.medicine_name?.toLowerCase().includes(event.target.value?.toLowerCase()) || item.order_type?.toLowerCase().includes(event.target.value?.toLowerCase())
        );
        setFinalFilterData(filteredData);
    };

    const handleFilter = () => {
        const start = new Date(startDate);
        const end = new Date(endDate);

        const filtered = data.filter(item => {
            const itemDate = new Date(item.created_at);
            return itemDate >= start && itemDate <= end;
        });
        setFinalFilterData(filtered);
    };

    if (!orders) {
        return <Loading />
    }

    // Pagination Logic
    const totalItems = data.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const paginatedData = data.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    // Total Purchase and Selling Price Calculations
    const totalPurchasePrice = data?.reduce((accumulator, item) => {
        return parseFloat(accumulator) + parseFloat(item.purchase_price);
    }, 0);

    const totalSellingPrice = data?.reduce((accumulator, item) => {
        return parseFloat(accumulator) + parseFloat(item.selling_price);
    }, 0);



    const [day, month, year] = startDate.split("/");

    const date = new Date(`${year}-${month}-${day}`);
    
    const monthName = date.toLocaleString('default', { month: 'long' });
    const fullYear = date.getFullYear(); 
    
    const monthYear = `${monthName} ${fullYear}`;



    return (
        <div className='container-fluid'>
            <ToastContainer />
            <div className='card p-3 rounded-0 border-0'>
                <div className='py-4 d-flex justify-content-between'>
                    <div className='d-flex align-items-center gap-2'>
                        <input
                            type="date"
                            className='form-control'
                            value={startDate}
                            onChange={e => setStartDate(e.target.value)}
                        />
                        <input
                            type="date"
                            className='form-control'
                            value={endDate}
                            onChange={e => setEndDate(e.target.value)}
                        />
                        <input
                            type="number"
                            className='form-control w-50'
                            defaultValue={10}
                            onChange={e => setItemsPrice(parseInt(e.target.value))}
                        />
                        <button className='btn btn-primary' onClick={handleFilter}>Filter</button>
                    </div>

                    <div className='d-flex align-items-center gap-3'>
                        <button className='btn btn-primary' onClick={() => toPDF()}>Download PDF</button>
                        {user.role === 'admin' ? (
                            <div>
                                <Link to="/medicine" className='btn btn-primary'> + Add Order</Link>
                            </div>
                        ) : ''}
                    </div>
                </div>

                <div ref={targetRef} className='p-3'>
                    <div className='d-flex justify-content-between my-4 responsive-filter'>
                        <h2 className="text-secondary">Medicine Order History { startDate ? monthYear : ""}</h2>
                        <div className='search-medicine'>
                            <Form.Control type="text" name='search_medicine' placeholder="Search Medicine"
                                onChange={handleSearchChange} />
                        </div>
                    </div>

                    {loading ? (
                        <div className='col-lg-3 mx-auto text-center'>
                            <Spinner animation="border" variant="primary" />
                        </div>
                    ) : (
                        <>
                            <div className='table-responsive'>
                                <Table striped bordered hover className='table-light'>
                                    <thead>
                                        <tr className='text-center'>
                                            <th>No</th>
                                            <th>Medicine</th>
                                            <th>Category</th>
                                            <th>Company</th>
                                            <th>Quantity</th>
                                            <th>Selling Price</th>
                                            <th>Purchase Price</th>
                                            <th>Expired Date</th>
                                            <th>Order Date</th>
                                            <th>Order Type</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paginatedData.map((item, index) => (
                                            <tr className='text-center' key={index}>
                                                <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                <td>{item?.medicine?.medicine_name}</td>
                                                <td>{item?.category?.category_name}</td>
                                                <td>{item?.company?.company_name}</td>
                                                <td>{item?.quantity}</td>
                                                <td>{item?.selling_price}</td>
                                                <td>{item?.purchase_price}</td>
                                                <td>{item?.expired_date}</td>
                                                <td>
                                                {item?.created_at && new Intl.DateTimeFormat('en-BD', {
                                                    year: 'numeric',
                                                    month: 'long',
                                                    day: 'numeric',
                                                }).format(new Date(item.created_at))}
                                                </td>
                                                <td>{item?.order_type === 'sell' ? "Sell" : "Buy"}</td>
                                            </tr>
                                        ))}
                                        <tr className='text-center'>
                                            <td colSpan={4}></td>
                                            <td>Total</td>
                                            <td>{totalSellingPrice} TK</td>
                                            <td>{totalPurchasePrice} TK</td>
                                            <td colSpan={3}></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>

                            {/* Pagination Controls */}
                            <Pagination className="d-flex flex-wrap">
                                {Array.from({ length: totalPages }, (_, index) => (
                                    <Pagination.Item
                                        key={index + 1}
                                        active={index + 1 === currentPage}
                                        onClick={() => handlePageChange(index + 1)}
                                    >
                                        {index + 1}
                                    </Pagination.Item>
                                ))}
                            </Pagination>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ManageOrder;
