
import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useGetSpecificCategory from '../../hooks/categoryHooks/useGetSpecificCategory';
import useCategoryUpdate from '../../hooks/categoryHooks/useCategoryUpdate';



const UpdateCategory = () => {

    const { id } = useParams();
    const { category, getloading } = useGetSpecificCategory(Number(id));
  
    const {updateCategory,loading} = useCategoryUpdate();
    

    const handleUpdate = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const form = event.target as HTMLFormElement;
        const categoryName = form.category_name.value;
        await  updateCategory({category_name:categoryName, id:Number(id)})  

    }


    return (
        <div className='container'>
            <ToastContainer />
            <div className='row'>
                <div className='col-lg-6 mx-auto'>
                    <div className='card p-4 rounded-0 border-0'>
                        <div className='py-4 d-flex justify-content-between'>
                            <h2 className="text-secondary">Medicine Add</h2>
                            <div>
                                <Link to="/category" className='btn btn-primary'> Manage Medicine</Link>
                            </div>
                        </div>
                        <form onSubmit={handleUpdate}>
                            <div className='row gy-3'>

                                <div className='col-lg-12'>
                                    <Form.Group>
                                        <label className="mb-2">Category Name</label>
                                        <Form.Control type="text" name='category_name' defaultValue={category?.category_name} placeholder="Medicine Name" required />
                                    </Form.Group>
                                </div>

                            </div>


                            <Button variant="primary mt-4" type="submit">
                               {loading ? "Loading..." : "Update"} 
                            </Button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateCategory;

