import axios from 'axios';
import React, { useEffect, useState } from 'react';

const useGetOrder = () => {

    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState<boolean>(false);
  
    const token = localStorage.getItem('token')

    const getOrders = async () => {
        setLoading(true)

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/getorder`, {
                headers: {
                    Authorization: 'Bearer' + ' ' + token,
                },
            });

            setOrders(response.data.orders)
            setLoading(false);

        } catch (error) {
            console.log(error);

        }

    }


    useEffect(() => {
      getOrders();
  
    }, []);

    return {orders, loading, setOrders};
};

export default useGetOrder;