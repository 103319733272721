import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const useAddCompnay = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const token = localStorage.getItem('token')
    const navigate = useNavigate();

    const addCompnaySubmit = async (event) => {
        event.preventDefault();
        setLoading(true)
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/company/add`, {
                company_name: event.target.company_name.value,
            }, {
                headers: {
                    Authorization: 'Bearer' + ' ' + token,
                },
            });

            console.log(response)
            if (response.data.success) {
                toast.success(response.data.msg)
                setTimeout(() => {
                    navigate('/company')
                }, 1000);
            } else if (response.data.error) {
                toast.error(response.data.msg)
            }

        } catch (error) {
            console.log(error);

        }finally{
            setLoading(true)
        }

    }

    return {addCompnaySubmit,loading};
};

export default useAddCompnay;