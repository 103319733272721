import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useGetSpecificCompnay from '../../hooks/companyHooks/useGetSpecificCompnay';
import useCompanyUpdate from '../../hooks/companyHooks/useCompanyUpdate';

const UpdateCompany = () => {


    let { id } = useParams();
    const {company} = useGetSpecificCompnay(Number(id));

    const  {updateCompany, loading} = useCompanyUpdate();
    

    const handleUpdate = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const form = event.target as HTMLFormElement;
        const categoryName = form.company_name.value;
        await  updateCompany({company_name:categoryName, id:Number(id)})  

    }


    return (
        <div className='container'>
            <ToastContainer />
            <div className='row'>
                <div className='col-lg-6 mx-auto'>
                    <div className='card p-4 rounded-0 border-0'>
                        <div className='py-4 d-flex justify-content-between'>
                            <h2 className="text-secondary">Medicine Add</h2>
                            <div>
                                <Link to="/medicine" className='btn btn-primary'> Manage Medicine</Link>
                            </div>
                        </div>
                        <form onSubmit={handleUpdate}>
                            <div className='row gy-3'>

                                <div className='col-lg-12'>
                                    <Form.Group>
                                        <label className="mb-2">Company Name</label>
                                        <Form.Control type="text" name='company_name' defaultValue={company?.company_name} placeholder="Medicine Name" required />
                                    </Form.Group>
                                </div>

                            </div>


                            <Button variant="primary mt-4" type="submit">
                                Update
                            </Button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateCompany;

