import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Link, useNavigate} from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useCagory from './../../hooks/categoryHooks/useCagory';
import useGetCompany from './../../hooks/companyHooks/useGetCompany';

const MedicineAdd = () => {



    const [totalPrice,setTotalPurchasePrice] = useState<number>(0);
    const [totalPricePerPice,setTotalPurchasePricePerPice] = useState<number>(0);

    const [loading, setLoading] = useState<boolean>(false)

    const { category } = useCagory();
    const { company } = useGetCompany();

    const token = localStorage.getItem('token')
    const navigate = useNavigate();


    const handleSubmit = async (event:React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true)
        const form = event.target as HTMLFormElement;

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/medicine/add`, {
                medicine_name: form.medicine_name.value,
                category_id: form.category_id.value,
                company_id: form.company_id.value,
                purchase_date: form.purchase_date.value,
                purchase_price_pice: form.purchase_price_pice.value ? parseFloat(form.purchase_price_pice.value) : 0,
                purchase_price: form.purchase_price.value ? parseFloat(form.purchase_price_pice.value) *  parseInt(form.stock.value) : 0,
                selling_price: form.selling_price.value ? parseFloat(form.selling_price.value) : 0,
                selling_price_pice: form.selling_price_pice.value ? parseFloat(form.selling_price_pice.value) : 0,
                expired_date: form.expired_date.value,
                stock: form.stock.value,
                toal_stock: form.toal_stock.value,
            }, {
                headers: {
                    Authorization: 'Bearer' + ' ' + token,
                },
            });

            if (response.data?.success) {
                toast.success(response.data.msg)
                setTimeout(()=>{
                    navigate('/medicine')
                },1000)
            } else{
                toast.error(response.data.msg)
            }

        } catch (error) {
            console.log(error);

        }finally{
            setLoading(false)
        }

    }



    const totalPurchasePrice = (quantity:number) => {
       const price = quantity * totalPricePerPice
       setTotalPurchasePrice(price)
    }


    return (
        <div className='container'>
            <ToastContainer />
            <div className='card p-4 rounded-0 border-0'>
                <div className='py-4 d-flex justify-content-between'>
                    <h2 className="text-secondary">Medicine Add</h2>
                    <div>
                        <Link to="/medicine" className='btn btn-primary'> Manage Medicine</Link>
                    </div>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className='row gy-3'>
                        <div className='col-lg-6'>
                            <Form.Group>
                                <label className="mb-2">Medicine Name</label>
                                <Form.Control type="text" name='medicine_name' placeholder="Medicine Name" required />
                            </Form.Group>
                        </div>

                        <div className='col-lg-6'>
                            <Form.Group>
                                <label className="mb-2">Company Name</label>
                                <select className="form-select" name='company_id' required>
                                    <option selected>Select Medicine Company</option>
                                    {company?.map(item => {
                                        return <option key={item?.id} value={item?.id}>{item?.company_name}</option>
                                    })}
                                    
                                </select>
                            </Form.Group>
                        </div>


                        <div className='col-lg-3'>
                            <Form.Group>
                                <label className="mb-2">Category</label>
                                <select className="form-select" name='category_id' required>
                                    <option selected>Select Medicine Category</option>
                                    {
                                        category?.map(item => {
                                            return <option key={item?.id} value={item?.id}>{item?.category_name}</option>
                                        })
                                    }
                                </select>
                       
                            </Form.Group>
                        </div>


                        <div className='col-lg-3'>
                            <Form.Group>
                                <label className="mb-2">Purchase Date</label>
                                <Form.Control type="date" name='purchase_date' placeholder="Purchase Date" required />
                            </Form.Group>
                        </div>

                        <div className='col-lg-3'>
                            <Form.Group>
                                <label className="mb-2">Purchase Price Per Medicine</label>
                                <Form.Control type="number" name='purchase_price_pice' placeholder="Purchase Price" step="0.001"  onChange={(e)=>{setTotalPurchasePricePerPice(parseFloat(e.target.value))}}/>
                            </Form.Group>
                        </div>


                        <div className='col-lg-3'>
                            <Form.Group>
                                <label className="mb-2">Total Stock</label>
                                <Form.Control type="number" name='toal_stock' placeholder="Total Quantity" required />
                            </Form.Group>

                        </div>

                        <div className='col-lg-3'>
                            <Form.Group>
                                <label className="mb-2">Quantity</label>
                                <Form.Control type="number" name='stock' placeholder="Quantity" onChange={(e)=>{totalPurchasePrice(parseFloat(e.target.value))}} required />
                            </Form.Group>

                        </div>


                        <div className='col-lg-3'>
                            <Form.Group>
                                <label className="mb-2">Total Purchase Price</label>
                                <Form.Control type="number" name='purchase_price' placeholder="Purchase Price" value={totalPrice} step="0.001" readOnly/>
                            </Form.Group>
                        </div>


                        <div className='col-lg-3'>
                            <Form.Group>
                                <label className="mb-2">Total Selling Price</label>
                                <Form.Control type="number" name='selling_price' placeholder="Selling Price" step="0.001"/>
                            </Form.Group>
                        </div>

                        <div className='col-lg-3'>
                            <Form.Group>
                                <label className="mb-2">Selling Price Per Medicine</label>
                                <Form.Control type="number" name='selling_price_pice' placeholder="Selling Price" step="0.001"/>
                            </Form.Group>
                        </div>

                        <div className='col-lg-3'>
                            <Form.Group>
                                <label className="mb-2">Expired Date</label>
                                <Form.Control type="date" name='expired_date' placeholder="Medicine Date" required />
                            </Form.Group>

                        </div>


                    </div>


                    <Button variant="primary mt-4" type="submit">
                         {loading ? <div className='d-flex align-items-center'><span className='pe-2'>Submit</span><Spinner animation="border" style={{ width: '12px', height: '12px' }}  variant="light" /> </div> : "Submit"}
                    </Button>
                </form>
            </div>
        </div>
    );
};

export default MedicineAdd;