import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const useAddCategory = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error | null>(null);
    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    const addCategory = async (category_name: string) => {
        setLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/category/add`, {
                category_name,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.data.success) {
                toast.success(response.data.success);
                setTimeout(() => {
                    navigate('/category');
                }, 1000);
            } else if (response.data.error) {
                toast.error(response.data.error);
            }
        } catch (error: any) {
            setError(error);
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    return { addCategory, loading, error };
};

export default useAddCategory;
