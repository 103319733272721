import React, { useEffect, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

import useCagory from '../../hooks/categoryHooks/useCagory';
import useGetCompany from '../../hooks/companyHooks/useGetCompany';

interface Medicine {
    medicine_name?: string;
    company_id?: number;
    category_id?: number;
    purchase_date?: string;
    purchase_price_pice?: string;
    stock?: string;
    toal_stock?: string;
    selling_price?: string;
    selling_price_pice?: string;
    expired_date?: string;
}

const MedicineUpdate = () => {


    const [medicine, setMedicine] = useState<Medicine>({});
    const [getloading, setGetLoading] = useState<boolean>(false)

    const { category, loading } = useCagory();
    const { company } = useGetCompany();

    const [totalPrice,setTotalPurchasePrice] = useState<number>(0);
    const [totalPricePerPice,setTotalPurchasePricePerPice] = useState<number>(0);

    const token = localStorage.getItem('token')
    let { id } = useParams();
    const navigate = useNavigate();

    const getSpecificMedicine = async () => {

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/medicine/getitem/${id = id}`, {
                headers: {
                    Authorization: 'Bearer' + ' ' + token,
                },
            });

            setMedicine(response.data?.medicine);
            setTotalPurchasePricePerPice(parseFloat(response.data?.medicine?.purchase_price_pice));
            const totalprice = parseFloat(response.data?.medicine?.stock) * parseFloat(response.data?.medicine?.purchase_price_pice)
            setTotalPurchasePrice(totalprice)

        } catch (error) {
            console.log(error);

        }

    }

    useEffect(() => {
        getSpecificMedicine()
    }, [])


    const handleUpdate = async (event:React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setGetLoading(true);
        const form = event.target as HTMLFormElement;

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/medicine/edit`, {
                medicine_name: form.medicine_name.value,
                category_id: form.category_id.value,
                company_id: form.company_id.value,
                purchase_date: form.purchase_date.value,
                purchase_price_pice: form.purchase_price_pice.value ? parseFloat(form.purchase_price_pice.value) : 0,
                purchase_price: form.purchase_price.value ? parseFloat(form.purchase_price_pice.value) *  parseInt(form.stock.value) : 0,
                selling_price: form.selling_price.value ? parseFloat(form.selling_price.value) : 0,
                selling_price_pice: form.selling_price_pice.value ? parseFloat(form.selling_price_pice.value) : 0,
                expired_date: form.expired_date.value?form.expired_date.value:0,
                stock: form.stock.value,
                toal_stock: form.toal_stock.value,
                id: parseInt(id),
            }, {
                headers: {
                    Authorization: 'Bearer' + ' ' + token,
                },
            });

            if (response.data.success) {
                toast.success(response.data.msg)

                setTimeout(()=>{
                    navigate('/medicine')
                },1000)

            } else if (!response.data.success) {
                toast.error(response.data.msg)
            }



        } catch (error) {
            console.log(error);

        }finally{
            setGetLoading(false)
        }

    }

    const totalPurchasePrice = (quantity:number) => {
        const price = quantity * totalPricePerPice
        setTotalPurchasePrice(price)
     }
 

    return (
        <div className='container'>
            <ToastContainer />
            <div className='card p-4 rounded-0 border-0'>
                <div className='py-4 d-flex justify-content-between'>
                    <h2 className="text-secondary">Medicine Update</h2>
                    <div>
                        <Link to="/medicine" className='btn btn-primary'> Manage Medicine</Link>
                    </div>
                </div>
                {
                    loading ? (
                        <div className='col-lg-3 mx-auto text-center'>
                          <Spinner animation="border" variant="primary" /> 
                        </div>
                    ) : (
                        <form onSubmit={handleUpdate}>
                    <div className='row gy-3'>
                        <div className='col-lg-6'>
                            <Form.Group>
                                <label className="mb-2">Medicine Name</label>
                                <Form.Control type="text" defaultValue={medicine?.medicine_name} name='medicine_name' placeholder="Medicine Name" />
                            </Form.Group>
                        </div>
                        
                        <div className='col-lg-6'>
                            <Form.Group>
                                <label className="mb-2">Company</label>
                                <select className="form-select" name='company_id' aria-label=".form-select-lg example" required>
                                    {
                                        company?.map(company => {
                                            return <option key={company?.id} value={company?.id} selected={company?.id == medicine?.company_id}>{company?.company_name}</option>
                                        })
                                    }
                                    
                                </select>
                            </Form.Group>
                        </div>


                        <div className='col-lg-3'>
                            <Form.Group>
                                <label className="mb-2">Category</label>
                                <select className="form-select" name='category_id' aria-label=".form-select-lg example"  required>
                                    {
                                        category?.map(category => {
                                            return <option key={category?.id} value={category?.id} selected={category?.id == medicine?.category_id}>{category?.category_name}</option>
                                        })
                                    }
                                    
                                </select>
                       
                            </Form.Group>
                        </div>


                        <div className='col-lg-3'>
                            <Form.Group>
                                <label className="mb-2">Purchase Date</label>
                                <Form.Control type="date" defaultValue={medicine?.purchase_date} name='purchase_date' placeholder="Purchase Date" />
                            </Form.Group>
                        </div>

                        <div className='col-lg-3'>
                            <Form.Group>
                                <label className="mb-2">Purchase Price Per Medicine</label>
                                <Form.Control type="number" defaultValue={medicine?.purchase_price_pice} name='purchase_price_pice' placeholder="Price"  step="0.001"  onChange={(e)=>{setTotalPurchasePricePerPice(parseFloat(e.target.value))}}/>
                            </Form.Group>
                        </div>

                        <div className='col-lg-3'>
                            <Form.Group>
                                <label className="mb-2">Total Stock</label>
                                <Form.Control type="number" defaultValue={medicine?.toal_stock} name='toal_stock' placeholder="Quantity" />
                            </Form.Group>

                        </div>
                        <div className='col-lg-3'>
                            <Form.Group>
                                <label className="mb-2">Quantity</label>
                                <Form.Control type="number" defaultValue={medicine?.stock}  onChange={(e)=>{totalPurchasePrice(parseFloat(e.target.value))}} name='stock' placeholder="Quantity" />
                            </Form.Group>

                        </div>


                        <div className='col-lg-3'>
                            <Form.Group>
                                <label className="mb-2">Total Purchase Price</label>
                                <Form.Control type="number" value={totalPrice} name='purchase_price' placeholder="Price"  step="0.001" readOnly/>
                            </Form.Group>
                        </div>


                        <div className='col-lg-3'>
                            <Form.Group>
                                <label className="mb-2">Total Selling Price</label>
                                <Form.Control type="number" defaultValue={medicine?.selling_price} name='selling_price' placeholder="Price" />
                            </Form.Group>
                        </div>

                        <div className='col-lg-3'>
                            <Form.Group>
                                <label className="mb-2">Selling Price per Medicine</label>
                                <Form.Control type="number" defaultValue={medicine?.selling_price_pice} name='selling_price_pice' placeholder="Price" step="0.001"/>
                            </Form.Group>
                        </div>

                        <div className='col-lg-3'>
                            <Form.Group>
                                <label className="mb-2">Expired Date</label>
                                <Form.Control type="date" defaultValue={medicine?.expired_date} name='expired_date' placeholder="Medicine Date" />
                            </Form.Group>

                        </div>

                       

                    </div>


                    <Button variant="primary mt-4" type="submit">
                        {getloading ? <div className='d-flex align-items-center'><span className='pe-2'>Submit</span><Spinner animation="border" style={{width: '12px', height: '12px'}}  variant="light" /> </div> : "Submit"}
                    </Button>
                </form>
                    )
                }
                
            </div>
        </div>
    );
};

export default MedicineUpdate;