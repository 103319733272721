/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { Spinner } from 'react-bootstrap';
const Loading = () => {
    return (
        <div className='mt-5 pt-5'>
             <div className='container'>
                <div className='row'>
                    <div className='col-lg-3 mx-auto text-center'>
                    <Spinner animation="border" variant="primary" /> 
                    </div>
                </div>
             </div>
        </div>
    );
};

export default Loading;