import axios from 'axios';
import React, { useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };



const PasswordResetSubmit = () => {

    const query = useQuery();
    const token = query.get('token');
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false)


    const validateEmail = (email: string): boolean => {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(String(email).toLowerCase());
    };
    

    const passwordResetSubmit = async (event:React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);

        const form = event.target as HTMLFormElement;

            const email = form.email.value;
            const password = form.password.value;
            const confirm_password = form.newpassword.value;

        if (!validateEmail(email)) {
            toast.error('Your email is not valid')
            return 0
        }

        if(password !== confirm_password){
            toast.error('Two password does not match')
            return 0
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/new-password`, {
                email: email,
                token: token,
                password: password,
            });

            if (response.data.success === true) {
                toast.success(response.data.msg)

                 setTimeout(() => {
                    navigate("/login");
                  }, 1000);


            } else {
                toast.error(response.data.msg)
            }

        } catch (error) {
            console.log(error);

        }finally{
            setLoading(false);
        }
    }


    return (
        <div className='container pt-5 mt-5'>
            <ToastContainer />
            <div className='row'>
                <div className='col-lg-4 mx-auto'>
                    <div className='card p-3'>
                        <h2 className='text-secondary py-4 text-center'>Password Reset</h2>
                        <form onSubmit={passwordResetSubmit}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="text" name="email" placeholder="Enter email" />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" name="password" placeholder="********" />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" name="newpassword" placeholder="********" />
                            </Form.Group>

                            <div className='text-center py-4'>
                                <Button variant="primary" type="submit">
                                   {loading ? <div className='d-flex align-items-center'><span className='pe-2'>Password Reset Submit</span><Spinner animation="border" style={{ width: '12px', height: '12px' }}  variant="light" /> </div> : "Password Reset Submit"}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PasswordResetSubmit;