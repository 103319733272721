import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

type PropsType = {
    category_name: string;
    id: number;
};

const useCategoryUpdate = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const token = localStorage.getItem('token');
    const navigate = useNavigate();

    type ArgTpe = {
        category_name : string,
        id:number
    }

    const updateCategory = async ({ category_name ,id} : ArgTpe) => {
        console.log(id)
        if (!token) {
            toast.error('No token found. Please login.');
            return;
        }

        setLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/category/edit`,
                {
                    category_name,
                    id,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.data.success) {
                toast.success(response.data.msg);
                setTimeout(() => {
                    navigate('/category');
                }, 1000);
                
            } else {
                toast.error(response.data.msg);
            }
        } catch (error) {
            toast.error('An error occurred while updating the category.');
            console.error('Update Category Error:', error);
        } finally {
            setLoading(false);
        }
    };

    return { updateCategory, loading };
};

export default useCategoryUpdate;
