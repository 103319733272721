import { useEffect, useState } from 'react';
import axios from 'axios';

interface DashboardData {
    medicineCount: number;
    brandCount: number;
    categoryCount: number;
    orderCount: number;
    sellOrderCount: number;
    purchaseOrderCount: number;
}

const useDashboard = () => {
    const [dashboardData, setDashboardData] = useState<DashboardData | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);
    const token = localStorage.getItem('token');
    const apiUrl = process.env.REACT_APP_API_URL;

    const getOrders = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${apiUrl}/dashboard/data`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.data) {
                setDashboardData(response.data);
            }
        } catch (error: any) {
            setError(error);
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getOrders();
    }, []);

    return { dashboardData, loading, error };
};

export default useDashboard;
