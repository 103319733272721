import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

type cateogryType = {
    category_name : string
}


const useGetSpecificCategory = (id:number) => {

    
    const [category, setCategory] = useState<cateogryType | null>(null);
    const [getloading, setLoading] = useState<boolean>(true);

    const token = localStorage.getItem('token')


    const getSpecificCategory= async () => {
        setLoading(true)

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/category/getitem/${id = id}`, {
                headers: {
                    Authorization: 'Bearer' + ' ' + token,
                },
            });

            setCategory(response.data.category);
            setLoading(false)

        } catch (error) {
            console.log(error);

        }

    }

    useEffect(() => {
        getSpecificCategory()
    }, [])

    return {category,getloading};
};

export default useGetSpecificCategory;