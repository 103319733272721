/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Spinner, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import EditIcon from '../../components/svg/EditIcon';
import DeleteIcon from '../../components/svg/DeleteIcon';
import useCagory from '../../hooks/categoryHooks/useCagory';
import axios from 'axios';

const ManageCategory = () => {
    // Use object destructuring since the hook returns an object
    const { category, setCategory, loading, error } = useCagory();

    const token = localStorage.getItem('token');

    // Function to delete a category
    const categoryDelete = async (id: number) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/category/delete/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response?.data?.success) {
                // Show success toast
                toast.success(response.data.success);

                // Update category list after deletion
                const newCategory = category?.filter(item => item.id !== id);
                setCategory(newCategory || []);
            } else if (response.data.error) {
                // Show error toast
                toast.error(response.data.error);
            }
        } catch (error) {
            console.error(error);
        }
    };

    if (error) return <p>Error loading categories</p>; // Handle any errors

    return (
        <div className='container'>
            <ToastContainer />
            <div className='card p-3 rounded-0 border-0'>
                <div className='py-4 d-flex justify-content-between'>
                    <h2 className="text-secondary">Manage Category</h2>
                    <div>
                        <Link to="/category/add" className='btn btn-primary'>
                            + Add Category
                        </Link>
                    </div>
                </div>
                {loading ? (
                            <div className="d-flex justify-content-center">
                            <Spinner animation="border" variant="primary" /> {/* Bootstrap Spinner */}
                          </div>
                        ) : (
                <Table striped bordered hover>
                    <thead>
                        <tr className='text-center'>
                            <th>No</th>
                            <th>Category</th>
                            <th>Category Slug</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                  
                            <tbody>
                            {category?.map((item, index) => (
                                <tr className='text-center' key={item.id}>
                                    <td>{index + 1}</td>
                                    <td>{item?.category_name}</td>
                                    <td>{item?.category_slug}</td>
                                    <td className='d-flex align-items-center gap-4 justify-content-center'>
                                        <Link to={`/category/update/${item?.id}`}>
                                            <EditIcon />
                                        </Link>
                                        <a  
                                          href='#'
                                            onClick={() => categoryDelete(item?.id)}
                                            className='text-danger'
                                        >
                                            <DeleteIcon />
                                        </a>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                       
                   
                </Table>
                 )}
            </div>
        </div>
    );
};

export default ManageCategory;
