import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useAddCategory from '../../hooks/categoryHooks/useAddCategory';

const AddCategory = () => {


    const  { addCategory, loading} = useAddCategory();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const form = event.target as HTMLFormElement;
        const categoryName = form.category_name.value;
        addCategory(categoryName);
    };

    return (
        <div className='container'>
            <ToastContainer />
            <div className='row'>
                <div className='col-lg-6 mx-auto'>
                    <div className='card p-4 rounded-0 border-0'>
                        
                            <div className="py-4 d-flex justify-content-between">
                                <h2 className="text-secondary">Category Add</h2>
                                <div>
                                    <Link to="/category" className="btn btn-primary">Manage Category</Link>
                                </div>
                            </div>
                    
                       
                        <form onSubmit={handleSubmit}>
                            <div className='row gy-3'>
                                <div className='col-lg-12'>
                                    <Form.Group>
                                        <label className="mb-2">Category Name</label>
                                        <Form.Control type="text" name='category_name' placeholder="Category Name" required />
                                    </Form.Group>
                                </div>
                            </div>
                            <Button variant="primary mt-4" type="submit">
                                {loading ? "Loading..." : "Add Category" }
                            </Button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddCategory;