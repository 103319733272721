import axios from 'axios';
import React, { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Registration = () => {

    const [loading, setLoading] = useState<boolean>(false)

    const navigate = useNavigate();

    const handleLogin = async (event:React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);

        const form = event.target as HTMLFormElement;
        const name = (form.elements.namedItem("name") as HTMLInputElement).value;
        const email = (form.elements.namedItem("email") as HTMLInputElement).value;
        const password = (form.elements.namedItem("password") as HTMLInputElement).value;

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/sign_up`, {
                name,
                email,
                password
            });

            if (response.data.success) {
                toast.success(response.data.success)
                setTimeout(()=>{
                    navigate('/login');
                },1000)
            } else {
                toast.error('Registration Faild')
            }

        } catch (error) {
            console.log(error);

        }finally{
            setLoading(false);
        }

    }

    return (
        <div>
            <div className='container pt-5'>
                <ToastContainer />
                <div className='row'>
                    <div className='col-lg-6 mx-auto'>
                        <div className='card p-5'>
                            <h2 className="text-center pb-5 text-secondary">Registration In Your Account</h2>
                            <form onSubmit={handleLogin}>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Your Name</Form.Label>
                                    <Form.Control type="text" name="name" placeholder="Your Name" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control type="email" name="email" placeholder="Enter email" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" name="password" placeholder="Password" />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Confirm Password</Form.Label>
                                    <Form.Control type="password" name="password_confirmation" placeholder="Confirm Password" />
                                </Form.Group>

                                <div className='text-center py-4'>
                         
                                    <Button variant="primary" type="submit">
                                       {loading ? <div className='d-flex align-items-center'><span className='pe-2'>Registration</span><Spinner animation="border" style={{ width: '12px', height: '12px' }}  variant="light" /> </div> : "Registration"}
                                    </Button>
                                </div>
                            </form>

                            <div className="card-body text-center">
                                <Link to='/login' className='nav-link'>Login Your Account</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Registration;