import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate} from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type companyType = {
    company_name : string
    id : number
}

const useCompanyUpdate = () => {

    const [loading,setLaoding] = useState<boolean>(false)
    const navigate = useNavigate();
    const token = localStorage.getItem('token')


    const updateCompany = async ({id,company_name}:companyType) => {

        setLaoding(true);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/company/edit`, {
                company_name: company_name,
                id: id,
            }, {
                headers: {
                    Authorization: 'Bearer' + ' ' + token,
                },
            });

            if (response?.data?.success) {
                toast.success(response.data.msg)

                setTimeout(() => {  
                    navigate("/company");
                }, 1000);
            } else{
                toast.error(response.data.msg)
            }

        } catch (error) {
            console.log(error);

        } finally{
            setLaoding(true);
        }

    }


    return {updateCompany,loading};
};

export default useCompanyUpdate;