import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useAddCompnay from '../../hooks/companyHooks/useAddCompnay';


const AddCompany = () => {

const {addCompnaySubmit,loading} = useAddCompnay();
    

    return (
        <div className='container'>
            <ToastContainer />
            <div className='row'>
                <div className='col-lg-6 mx-auto'>
                    <div className='card p-4 rounded-0 border-0'>
                        <div className='py-4 d-flex justify-content-between'>
                            <h2 className="text-secondary">Company Add</h2>
                            <div>
                                <Link to="/company" className='btn btn-primary'> Manage Company</Link>
                            </div>
                        </div>
                        <form onSubmit={addCompnaySubmit}>
                            <div className='row gy-3'>
                                <div className='col-lg-12'>
                                    <Form.Group>
                                        <label className="mb-2">Company Name</label>
                                        <Form.Control type="text" name='company_name' placeholder="Company Name" required />
                                    </Form.Group>
                                </div>
                            </div>
                            <Button variant="primary mt-4" type="submit">
                                {loading ? "Loading..." : "Submit"}
                            </Button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddCompany;