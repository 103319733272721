/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios';
import React, { useEffect, useState } from 'react';

const useGetCompany = () => {

    const [company, setcompany] = useState([]);
    const [loading, setLoading] = useState<boolean>(false);

    const token = localStorage.getItem('token')

    const getcompany = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/company`, {
                headers: {
                    Authorization: 'Bearer' + ' ' + token,
                },
            });

            setcompany(response.data.companys)

        } catch (error) {
            console.log(error);

        }finally{
            setLoading(false)
        }

    }

    useEffect(() => {
        getcompany()
    }, [])



    return {company,loading,setcompany};
};

export default useGetCompany;