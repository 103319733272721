/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import axios from 'axios';
import { Spinner, Table } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import useGetUser from '../../hooks/userHooks/useGetUser';


const ManageUser = () => {
   
    const {users, loading, setUsers} = useGetUser()
    const [permissionLoading, setPermissionLoading] = useState<number[]>([]);

    const token = localStorage.getItem('token');

    const setLoading = (id: number, isLoading: boolean) => {
        if (isLoading) {
            setPermissionLoading((prev) => [...prev, id]);
        } else {
            setPermissionLoading((prev) => prev.filter((userId) => userId !== id));
        }
    };


    const userPermission = async (id:number) => {
        setLoading(id, true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/userpermission/${id = Number(id)}`, {
                headers: {
                    Authorization: 'Bearer' + ' ' + token,
                },
            });

            console.log(response)
            if (response.data.success) {
                setUsers(response.data.users)
                toast.success(response.data.msg)

            } else if (response.data.success === false) {
                toast.error(response.data.msg)
            }

        } catch (error) {
            console.log(error);

        }finally{
            setLoading(id, false);
        }

    }

    const canclePermission = async (id:number) => {
        setLoading(id, true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/canclepermission/${id = id}`, {
                headers: {
                    Authorization: 'Bearer' + ' ' + token,
                },
            });

            console.log(response)
            if (response.data.success) {
                console.log(response.data.users)
                setUsers(response.data.users)
                toast.success(response.data.msg)

            } else if (response.data.success === false) {
                toast.error(response.data.msg)
            }

        } catch (error) {
            console.log(error);

        }finally{
            setLoading(id, false);
        }

    }




    return (
        <div className='container'>
            <ToastContainer />
            <div className='card p-3 rounded-0 border-0'>
                <div className='py-4 d-flex justify-content-between'>
                    <h2 className="text-secondary">Manage Medicine</h2>

                </div>
                {
                    loading ? (
                        <div className='col-lg-3 mx-auto text-center'>
                          <Spinner animation="border" variant="primary" /> 
                        </div>
                    ) : 
                    (
                        <Table striped bordered hover>
                        <thead>
                            <tr className='text-center'>
                                <th>No</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                users?.map((item, index) => {
                                    return (
                                        <tr className='text-center' key={item.id}>
                                            <td>{index + 1}</td>
                                            <td>{item.name}</td>
                                            <td>{item.email}</td>
                                            <td className='d-flex align-items-center gap-4 justify-content-center'>
                                                {
                                                    item.role === 'user' ? <a href='#' onClick={() => userPermission(Number(item.id))} className='btn btn-success btn-sm'>{permissionLoading.includes(item.id) ?   <div className='d-flex align-items-center'><span className='pe-2'>Permission</span><Spinner animation="border" style={{ width: '12px', height: '12px' }}  variant="light" /> </div>  : "Permission"}</a> : <a href='#' onClick={() => canclePermission(Number(item.id))} className='btn btn-danger btn-sm'>{permissionLoading.includes(item.id) ?   <div className='d-flex align-items-center'><span className='pe-2'>Cancel Permission</span><Spinner animation="border" style={{ width: '12px', height: '12px' }}  variant="light" /> </div>  : "Cancle Permission"}</a>
                                                }
                                                
                                            </td>
                                        </tr>
                                    )
                                })
                            }
    
                        </tbody>
                    </Table>
                    )
                }

              
            </div>
        </div>
    );
};

export default ManageUser;