import React, { useEffect, useState } from 'react';
import axios from 'axios';


const useGetUser = () => {

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState<boolean>(false);

    const token = localStorage.getItem('token');

    const getUserInfo = async () => {
        setLoading(true)

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/userinfo`, {
                headers: {
                    Authorization: 'Bearer' + ' ' + token,
                },
            });

            setUsers(response.data.users)

        } catch (error) {
            console.log(error);

        }finally{
            setLoading(false)
        }

    }

    useEffect(() => {
        getUserInfo()
    }, [])

    return {users,loading,setUsers};
};

export default useGetUser;